import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./routes/auth.route').then((m) => m.AuthRoutes),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./routes/dashboard.route').then((m) => m.DashboardRoute),
  },
];
